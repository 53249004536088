
body {
  background-color: #3498db;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

h1 {
  color: #fff;
  text-align: center;
  margin-top: 40px;
}
h1:hover{
  color: #000;
}

.dropdown-container {
  text-align: center;
  margin-top: 30%;
  margin-left: 20%;
}

.dropdown-btn {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  color: #3498db;
  font-weight: bold;
  position: relative; 
  width: 200px


}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #3498db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  top: calc(100% + 5px); /* Position the dropdown right below the button */
  left: 0;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  color: #fff;
  background-color: #ff0000;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-list li:hover {
  background-color: #000;
  color: #000;
}
