
.dropdown-container {
    
    position: relative;
    display: inline-block;
  }
  
  .dropdown-btn {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
  }
  
  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-list li:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-container:hover .dropdown-list {
    display: block;
  }
  